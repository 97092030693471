import {ParametrageWay, SouscriptionResource} from "@core/backend/models/souscription";
import {JsonValueOf} from "@core/helpers/json-manipulation.helpers";

export interface SouscriptionState {
  souscriptionEnCours: JsonValueOf<SouscriptionResource<ParametrageWay>> | null;
}


export class StateFactory {
  public static createInitialSouscriptionState(): SouscriptionState {
    return {
      souscriptionEnCours: null,
    }
  }
}
